@use 'sass:color';

/*********************
// # Accent
**********************/
@function accentHover($color) {
  @return color.scale($color, $lightness: -20%);
}

$color-magenta: #d81b60;
$color-magenta-hover: accentHover($color-magenta);
$color-berry: #ad1357;
$color-berry_hover: accentHover($color-berry);
$color-warm-red: #ff6450;
$color-warm-red-hover: accentHover($color-warm-red);
$color-papaya: #ff8d85;
$color-papaya_hover: accentHover($color-papaya);

$color-walnut: #e07f5d;
$color-walnut_hover: accentHover($color-walnut);
$color-signal: #f4501e;
$color-signal_hover: accentHover($color-signal);
$color-curry: #f09300;
$color-curry_hover: accentHover($color-curry);

$color-mustard: #f6bf25;
$color-mustard_hover: accentHover($color-mustard);
$color-macadamia: #f7c976;
$color-macadamia_hover: accentHover($color-macadamia);

$color-kelp: #afb83b;
$color-kelp_hover: accentHover($color-kelp);
$color-spinach: #5fbf89;
$color-spinach_hover: accentHover($color-spinach);
$color-mint: #80e5ac;
$color-mint_hover: accentHover($color-mint);
$color-leave: #6accbc;
$color-leave_hover: accentHover($color-leave);
$color-petrol: #0088b0;
$color-petrol_hover: accentHover($color-petrol);

$color-bright-blue: #8ec3ff;
$color-bright-blue_hover: accentHover($color-bright-blue);
$color-soft-blue: #d1f0f8;
$color-soft-blue_hover: accentHover($color-soft-blue);
$color-aqua: #a2eeff;
$color-aqua_hover: accentHover($color-aqua);
$color-azzurro: #4fe8e8;
$color-azzurro_hover: accentHover($color-azzurro);
$color-skylight: #039be5;
$color-skylight_hover: accentHover($color-skylight);
$color-bluehouse: #044ada;
$color-bluehouse_hover: accentHover($color-bluehouse);
$color-nightsky: #193f70;
$color-nightsky_hover: accentHover($color-nightsky);
$color-sky: #d7effb;
$color-sky-hover: accentHover($color-sky);

$color-flieder: #884dff;
$color-flieder_hover: accentHover($color-flieder);
$color-lilac: #5538d7;
$color-lilac_hover: accentHover($color-lilac);
$color-aubergine: #8e24aa;
$color-aubergine_hover: accentHover($color-aubergine);
$color-mellow: #b39ddb;
$color-mellow_hover: accentHover($color-mellow);

$color-powerpuff: #eb96eb;
$color-powerpuff_hover: accentHover($color-powerpuff);
$color-pink: #e05194;
$color-pink_hover: accentHover($color-pink);

$color-taro: #808080;
$color-taro_hover: accentHover($color-taro);
$color-rock: #9aa4b2;
$color-rock_hover: accentHover($color-rock);

$color-hazelnut: #ccac93;
$color-hazelnut_hover: accentHover($color-hazelnut);
$color-cacao: #795548;
$color-cacao_hover: accentHover($color-cacao);

/*********************
// # Product
(hover 20% darker or lighter depending on the given value)
**********************/

$color-primary: var(--color-primary, #3486ef); // whitelabel
$color-primary-hover: var(
  --color-primary-hover,
  color.scale(#3486ef, $lightness: -20%)
); // inherit whitelabel $color-primary
$color-secondary: var(--color-secondary, #193f70); // whitelabel
$color-secondary-hover: var(
  --color-secondary-hover,
  color.scale(#193f70, $lightness: -20%)
); // inherit whitelabel $color-secondary

/*********************
// # Functional
**********************/

$color-surface: var(--color-surface, #ffffff); // whitelabel
$color-surface-hover: var(
  --color-surface-hover,
  color.scale(#ffffff, $lightness: -5%)
); // inherit whitelabel $color-surface
$color-surface-current: #e6e8ea;

$color-background-light: var(--color-background-light, #f5f8ff); // whitelabel
$color-background-medium: var(
  --color-background-medium,
  color.scale(#f5f8ff, $lightness: -3%, $saturation: 50%)
); // inherit whitelabel $color-background-light
$color-background-white: #ffffff;

$color-border: #ced2d5;
$color-border-hover: color.scale($color-border, $lightness: -20%);
$color-border-disabled: color.scale($color-border, $lightness: 50%);
$color-border-light: color.scale(
  $color-border,
  $lightness: 50%
); // same like $color-border-disabled

$color-text-primary: var(--color-text-primary, #050b13); // whitelabel
$color-text-secondary: var(--color-text-secondary, #7c8592); // whitelabel
$color-text-disabled: #ced2d5;
$color-text-white: #ffffff;

// addition button text colors to switch between white and black depending on the product colors
$color-text-button-primary: var(--color-text-button-primary, #ffffff); // depending on color primary
$color-text-button-secondary: var(
  --color-text-button-secondary,
  #ffffff
); // depending on color secondary

$color-nav-background: var(--color-menu-bar-background, #050b13); // whitelabel
$color-nav-text-selected: var(--color-menu-bar-text-active, #ffffff); // whitelabel
$color-nav-text-selected-bg: var(
  --color-menu-bar-text-active-bg,
  #2d3239
); // depends on nav active color
$color-nav-text: var(--color-menu-bar-text-inactive, #ced2d5); // whitelabel

/*********************
// # Feedback
**********************/
$color-success: $color-spinach;
$color-success-hover: $color-spinach_hover;
$color-success-light: color.scale($color-success, $lightness: 84%);

$color-good: $color-mint;
$color-good-hover: $color-mint_hover;
$color-good-light: color.scale($color-good, $lightness: 84%);

$color-moderate: $color-macadamia;
$color-moderate-hover: $color-macadamia_hover;
$color-moderate-light: color.scale($color-moderate, $lightness: 84%);

$color-error: #ed5d57;
$color-error-hover: color.scale($color-error, $lightness: -20%);
$color-error-light: color.scale($color-error, $lightness: 84%);

$color-low: $color-walnut;
$color-low-hover: $color-walnut_hover;
$color-low-light: color.scale($color-low, $lightness: 84%);

$color-info: $color-skylight;
$color-info-hover: $color-skylight_hover;
$color-info-light: color.scale($color-info, $lightness: 90%); // only feedback light color with +90

$color-help: $color-flieder;
$color-help-hover: $color-flieder_hover;
$color-help-light: color.scale($color-help, $lightness: 84%);

/*************************************************************************
// # Other
// Colors that are just a mapping of colors from the official ColorPalette
**************************************************************************/

// # consumption color mapping
$color-consumption_power: $color-mustard;
$color-consumption_cold-water: $color-bluehouse;
$color-consumption_hot-water: $color-signal;
$color-consumption_heating: $color-flieder;
$color-consumption_photovoltaic: $color-spinach;
$color-consumption_no-type: $color-primary;

// TODO: Refactor more to be save to use it also in the future
// TODO: make sure these color can be used savely cause they rely on whitelabel colors
$color-white: #ffffff;
$color-black: #000;
$color-medium: #92949c;
$color-light: #f4f5f8;
