@use 'variables' as v;
@use 'colors' as c;

.container {
  &-primary {
    background-color: c.$theme-color-app-background;
  }

  &-light {
    background-color: c.$color-background-light;
  }

  &--with-spacing {
    margin-top: #{v.$base-size * 6};
    margin-bottom: #{v.$base-size * 5};
    position: relative;
  }

  @media (max-width: v.$screen-lg-max) {
    max-width: 90%;
  }

  @media (min-width: v.$screen-xl-min) {
    max-width: 1040px;
  }

  @media (min-width: v.$screen-xxl-min) {
    max-width: 1400px;
  }
}
