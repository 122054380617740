@use 'colors' as c;
@use 'variables' as v;

.offcanvas {
  > :first-child {
    height: 100%;
  }

  &-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &-header {
    padding: 2rem;
    border-bottom: 3px solid c.$color-border;

    .btn-close {
      box-shadow: var(--bs-btn-close-focus-shadow);
    }
  }

  &-body {
    padding: 2rem 2.5rem;

    @media (width < v.$screen-xs-max) {
      padding-inline: 1.5rem;
    }
  }

  &-end {
    top: 48px !important;
    width: 650px !important;
  }

  &-footer {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 65px;
    padding-inline: 2rem;
    background-color: c.$color-background-light;
    border-top: 1px solid c.$theme-color-neutral;

    @media (width < v.$screen-xs-max) {
      background-color: unset;
      border-top: unset;
      gap: 15px;
      padding-inline: 1rem;

      > rs-web-button {
        flex: 1;
      }
    }
  }
}
