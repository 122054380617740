@use 'colors' as c;

h1,
.h1 {
  font-style: normal;
  font-weight: $font-weight-bold;
}

h2,
.h2 {
  font-style: normal;
  font-weight: $font-weight-semibold;
}

h3,
.h3 {
  font-style: normal;
  font-weight: $font-weight-semibold;
}

h4,
.h4 {
  font-style: normal;
  font-weight: $font-weight-semibold;
}

.body {
  font-style: normal;
  font-weight: $font-weight-normal;
}

.nav-text {
  font-style: normal;
  font-weight: $font-weight-semibold;
  text-transform: uppercase;
}

.caption-header {
  text-transform: uppercase;
  font-size: 12px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: $color-text-secondary;
}

.h-full {
  height: 100%;
}

.h-90p {
  height: 90%;
}

.h-85p {
  height: 85%;
}

.h-78p {
  height: 78%;
}

.w-full {
  width: 100%;
}

.w-25p {
  width: 25%;
}

.w-75p {
  width: 75%;
}

.font-bold {
  font-weight: $font-weight-bold;
}

.font-semibold {
  font-weight: $font-weight-semibold;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: left;
}

.text-wrap-balance {
  text-wrap: balance;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-primary {
  color: c.$color-text-primary;
}

.text-size-xxxl {
  font-size: $font-size-xxxl;
}

.text-size-xl {
  font-size: $font-size-xl;
}

.text-size-lg {
  font-size: $font-size-large;
}

.text-size-md {
  font-size: $font-size-smaller;
}

.text-size-xs {
  font-size: $font-size-xs;
}

.text-secondary {
  color: $color-text-secondary;
}

.color-text-btn-primary {
  color: $color-text-button-primary;
}

.text-white {
  color: $color-text-white;
}

.text-disabled {
  color: $color-text-disabled;
}

.text-error {
  color: $color-error;
}

.bg-primary {
  background-color: $color-primary;
}

.bg-primary-10 {
  background-color: $color-background-light;
}

.bg-secondary {
  background-color: $color-secondary;
}

.bg {
  background-color: $color-background-light;
}

.bg-surface {
  background-color: $color-surface;
}

.btn {
  font-style: normal;
  font-weight: $font-weight-semibold;
  text-transform: var(--button-case);
}

.color-primary {
  color: $color-primary;
}

.color-secondary {
  color: $color-secondary;
}

.text-wrap {
  text-wrap: wrap;
}

.consumption_power {
  color: $color-consumption_power;
}

.consumption_hot-water {
  color: $color-consumption_hot-water;
}

.consumption_cold-water {
  color: $color-consumption_cold-water;
}

.consumption_heating {
  color: $color-consumption_heating;
}

.consumption_photovoltaic {
  color: $color-consumption_photovoltaic;
}

.consumption_no-type {
  color: $color-consumption_no-type;
}

.padding-70 {
  padding-top: 70%;
}

.color-bg-info {
  background-color: $color-info;
}

.color-bg-error {
  background-color: $color-error;
}

.color-bg-moderate {
  background-color: $color-moderate;
}

.color-bg-success {
  background-color: $color-success;
}

.color-bg-canceled {
  background-color: $color-border;
}
