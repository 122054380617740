/*
COLORS
*/
@import 'colors';

/*
TYPOGRAPHY
*/

$font-family: 'Source Sans Pro';
$font-family-default: $font-family, sans-serif;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$border-radius-12: 12;
