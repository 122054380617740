// web specific variables here...
@use 'colors' as c;
@import '../../../libs/shared/scss/variables';

//Layout

$base-size: 8px;

// Depths

$z-index-freshworks: 2147483000;
$z-index-batch-menu: $z-index-freshworks + 100;
$z-index-side-sheet: $z-index-batch-menu + 100;
$z-index-navigation: $z-index-side-sheet + 100;
$z-index-header: $z-index-navigation + 100;
$z-index-ad-block-info: $z-index-navigation + 100;
$z-index-modal: $z-index-ad-block-info + 100;
$z-index-tooltip: $z-index-modal + 100;
$z-index-toast: $z-index-modal + 100;

// UI Colors

$color-skeleton-base: c.$theme-color-neutral-alpha-50;
$color-skeleton-shine: c.$theme-color-app-background;

$color-card-border: c.$theme-color-neutral;
$color-card-border-hover: c.$theme-color-secondary-text;
$color-card-border-active: c.$theme-color-primary-accent;
$color-card-border-state-100: c.$theme-color-state-100;
$color-card-border-alert: #d81b60;

$color-badge-border: #fff;
$color-badge-text-dark: #000;
$color-badge-text-light: #fff;

$color-badge-contact-guest: c.$theme-color-state-500;
$color-badge-contact-exclusive: #808080;
$color-badge-contact-tenant: #afb83b;
$color-badge-contact-offline: c.$theme-color-state-900;
$color-badge-contact-anonymous: #884dff;
$color-badge-contact-manual: #4fe8e8;
$color-badge-contact-member: c.$theme-color-state-100-dark;
$color-badge-contact-project: #288b9e;
$color-badge-contact-dynamic: #e7ba14;
$color-badge-contact-self: #ff006c;

$color-badge-digital-contract-nect: #6accbc;
$color-badge-digital-contract-bank: #eb96eb;
$color-badge-digital-contract-identity: #158fad;

$color-badge-light-blue: #ebf2ff;

$color-data-privacy-zero: c.$theme-color-primary-accent-light;
$color-data-privacy-one: c.$theme-color-primary-accent;
$color-data-privacy-two: c.$theme-color-primary-accent-dark;
$color-data-privacy-three: c.$theme-color-secondary-accent;

$color-schufa-label-background: c.$theme-color-state-500;

$color-divider-line: c.$theme-color-neutral;

// Legacy color vars – translated
// TODO: replace old vars with new vars or name old vars "legacy"
//  (currently this is only a mapping/translation from new to old):

$background-primary: c.$theme-color-app-background;
$background-light: c.$theme-color-card-background;
$background-main-navigation: c.$theme-color-menu-background;

$secondary: c.$theme-color-primary-accent;
$secondary-light: c.$theme-color-primary-accent-light;
$secondary-dark: c.$theme-color-primary-accent-dark;

$main: c.$theme-color-secondary-accent;
$main-dark: c.$theme-color-secondary-accent-dark;

$black: c.$theme-color-primary-text;
$grey: c.$theme-color-secondary-text;

$button-text: c.$theme-color-button-text;

$blue: #1088d3;
$green: c.$theme-color-state-100;
$positive: c.$theme-color-state-100;
$green-dark: c.$theme-color-state-100-dark;
$yellow: c.$theme-color-state-500;
$warning: c.$theme-color-state-700;
$orange: c.$theme-color-state-700;
$red: c.$theme-color-state-900;
$negative: c.$theme-color-state-900;
$background-negative: c.$theme-color-state-900-ultra-light;
$red-dark: c.$theme-color-state-900-dark;
$banner-red: c.$theme-color-state-900-dark;

$white: #fff;
$smoke: c.$theme-color-app-background;
$platinum: c.$theme-color-neutral;
$platinum-light: c.$theme-color-neutral-alpha-50;

$intense-box-shadow-color: c.$theme-color-neutral;

$data-table-row-hover-color: #f5f5f5;

//Font Styles

$font-size-xxs: 11px;
$font-size-xs: 12px;
$font-size-smaller: 14px;
$font-size-small: 15px;
$font-size-regular: 16px;
$font-size-large: 18px;
$font-size-larger: 20px;
$font-size-xl: 22px;
$font-size-xxl: 24px;
$font-size-xxxl: 26px;

$font-weight-semibold: 600;

// Font Styles

$font-family-name-default: 'Source Sans Pro';
$font-family-default: $font-family-name-default, sans-serif;
//
//$font-size-tiny: 11px;
//$font-size-small: 12px;
$font-size-normal: 15px;
//$font-size-100: 17px;
//$font-size-large: 20px;
//
//$font-weight-normal: 400;
//$font-weight-semibold: 600;

/*
MISC
*/
$border-radius-small: 6px;
$border-radius-default: 12px;
$border-radius-medium: 18px;
$border-radius-large: 24px;
$border-width-default: 1px;

$card-padding: 12px;
$card-padding-sm: 10px;

$padding-tile-default: 12px;
$padding-card: 16px 12px;
$default-gap: 16px;
$box-shadow: 0 2 4 rgba(0, 0, 0, 0.1),
0 2 2 rgba(0, 0, 0, 0.15),
0 2 2 rgba(0, 0, 0, 0.25);
$box-shadow-default: 0 2 4 rgba(0, 0, 0, 0.1),
0 2 2 rgba(0, 0, 0, 0.15),
0 2 2 rgba(0, 0, 0, 0.25);
$box-shadow-input-field: 0 1px 2px #e5e9eb;

$input-padding-x: 15px;

// Transitions

$transition-duration-100: 0.2s; // e.g. for button background-color + color
$transition-duration-200: 0.5s; // e.g open/close sliding containers

// Override any imported variables here...
$border-radius-default: 12px;
$margin-x-default: 17px;
$margin-y-default: 10px;

$spacing-default-s: 8px;
$spacing-default-m: 12px;
$spacing-default-l: 16px;
$spacing-default-xl: 24px;
$spacing-default-xxl: 48px;

$box-shadow-default: 0px 4px 5px rgba(0, 0, 0, 0.1),
0px 1px 10px rgba(0, 0, 0, 0.15),
0px 2px 4px rgba(0, 0, 0, 0.25);

$screen-xs-max: 575px;
$screen-sm-min: $screen-xs-max + 1;
$screen-sm-max: 767px;
$screen-md-min: $screen-sm-max + 1;
$screen-md-max: 991px;
$screen-lg-min: $screen-md-max + 1;
$screen-lg-max: 1199px;
$screen-mac-13-max-width: 1440px;
$screen-xl-min: $screen-lg-max + 1;
$screen-xxl-min: 1600px;
$sidesheet-width: 650px;

$navigation-full-width-screen-max: $screen-sm-max;

//Depths

$z-index-freshworks: 2147483000;
$z-index-batch-menu: $z-index-freshworks + 100;
$z-index-navigation: $z-index-batch-menu + 100;
$z-index-side-sheet: $z-index-navigation + 100;
$z-index-header: $z-index-side-sheet + 100;
$z-index-ad-block-info: $z-index-side-sheet + 100;
$z-index-modal: $z-index-ad-block-info + 100;
$z-index-tooltip: $z-index-modal + 100;
$z-index-toast: $z-index-modal + 100;

//Transitions

$transition-duration-100: 0.2s; // e.g. for button background-color + color

//Filter

$hover-filter: brightness(110%); // e.g. for hover effect on background of Button or Badge component

$header-height: 48px;

// Form Elements

$placeholder-color: $grey;
$form-field-background-color: c.$theme-color-card-background;
$form-field-disabled-color: $platinum;
$form-field-border-color: $grey;
$form-field-edited-background-color: #eaf3fe;
$form-field-error-color: $red;
$form-field-error-background-color: #fdeeee; // TODO: 10 % of $negative
$form-field-border-radius: 6px;
$form-field-line-height: $font-size-normal * 1.3333;
$form-field-border-width: 1px;
$form-field-inner-spacing-y: $form-field-line-height * 0.15;
$form-field-inner-spacing-x: 15px;
$form-field-total-height: $form-field-line-height +
  (($form-field-inner-spacing-y + $form-field-border-width) * 2);
$form-field-label-color: $grey;
$form-field-asterisk-color: c.$theme-color-primary-accent;
$form-field-dropdown-arrow-size: 12px;
