// shared across all platforms and apps

/**
* The following are web specific (used with any web app targets)
*/
@forward 'utilities';
@forward 'vendors';
@forward 'forms';

@use '@material/elevation/styles';

// web specific variables
@import 'variables';
@import 'placeholders';
@import 'colors';

@import '../../shared/scss/index';

@import 'typography';

// web styles (create/import other scss files or define as needed)
@import 'spacing';
@import 'tags';
@import 'layouts';
@import 'fonts';
