$-lines: 3;

// creates classes to limit lines to 1,2 or 3
// ex.: line-clamp-1
@for $i from 1 through $-lines {
  .line-clamp-#{$i} {
    display: -webkit-box;
    -webkit-line-clamp: $i;
    -webkit-box-orient: vertical;
  }
}
