@use 'colors' as c;
@use 'variables' as v;

.ng-submitted input.ng-invalid,
.ng-dirty.ng-invalid.ng-touched {
  border-color: c.$color-warm-red;
}

.ng-invalid.ng-touched.ng-dirty {
  &:focus,
  &:checked {
    border-color: c.$color-warm-red;
    box-shadow: 0 0 0 0.1rem c.$color-papaya;
  }
}

.form-control {
  border-radius: v.$border-radius-default;
}

.form-check {
  margin-bottom: 0.5rem;
  border: 1px solid c.$color-border;
  padding: 0.5em 2em;
  border-radius: 12px;
  transition: all 0.2s ease-in-out;

  &-input {
    cursor: pointer;
    border-color: c.$theme-color-primary-text;
  }

  &:hover {
    cursor: pointer;
    border-color: c.$color-primary;
  }

  &:has(:checked) {
    border-color: c.$color-primary;
  }
}

input[slide-toggle] {
  position: relative;
  width: 44px;
  height: 24px;
  -webkit-appearance: none;
  background: c.$color-text-disabled;
  border-radius: 20px;
  transition: all 0.4s ease-in-out;

  &:hover {
    cursor: pointer;
  }

  &:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: c.$white;
    top: 2px;
    bottom: 2px;
    left: 2px;
    transition: all 0.2s ease-in-out;
  }

  &:checked {
    background: c.$color-primary;

    &:before {
      left: calc(44px - 20px - 2px);
    }
  }
}
