@use 'variables' as v;
@use 'colors' as c;
// Font sizes

.title-xxl {
  font-size: 43px;
  font-weight: 700;
  line-height: 52px;
}

.title-xl {
  font-size: 25px;
  line-height: 32px;
  font-weight: 600;
}

.title-m {
  font-size: 17px;
  line-height: 24px;
  font-weight: 600;
}

.title-s {
  font-size: 16px;
  line-height: 24px;
}

.title-xs {
  font-size: 12px;
  line-height: 16px;
}

.default-s {
  font-size: 14px;
  line-height: 20px;
}

// ------

.h2 {
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.36px;
}

.h4 {
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.204px;
}

.body {
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.36px;
}

hr {
  margin-top: 32px;
  margin-bottom: 24px;
}

.text-button {
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.18px;
}

.nav-text {
  font-size: 12px;
  line-height: 17px;
}

.cards-container {
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
}

.card {
  border: 1px solid v.$color-surface;
  background: v.$color-surface;
  box-shadow: v.$box-shadow-default;
  border-radius: v.$border-radius-default;
  margin: 5px;
}

.button-primary {
  border-radius: v.$border-radius-default;
  border-color: v.$color-primary;
  border-width: 1px;
  background-color: v.$color-primary;
  color: v.$color-secondary;
}

.button-secondary {
  border-radius: v.$border-radius-default;
  border-color: v.$color-secondary;
  border-width: 1px;
  background-color: v.$color-surface;
  color: v.$color-secondary;
}

.border-radius-bottom {
  border-bottom-left-radius: v.$border-radius-default;
  border-bottom-right-radius: v.$border-radius-default;
}

.details-page-content {
  margin: v.$margin-y-default v.$margin-x-default;
}

.border-radius-default {
  border-radius: v.$border-radius-default;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: c.$color-text-primary;
}

.primary-text {
  color: c.$color-text-primary;
}

.secondary-text {
  color: c.$color-text-secondary;
}

.font-weight-600 {
  font-weight: 600;
}

.text-balance {
  text-wrap: balance;
}
