@use 'variables' as v;
@use 'colors' as c;

%text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

%card-columns-gap-fix {
  > .card {
    display: flex;
    page-break-inside: avoid;
    break-inside: avoid;
  }
}

%only-vertical-auto-scroll {
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

%glass-effect {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);

  @-moz-document url-prefix() {
    background-color: c.$white !important;
  }
}

%card {
  padding: 15px;
  box-shadow: 0 1px 2px c.$theme-color-neutral;
  background-color: c.$white;
}

%form-control {
  // TODO: convert to bootstrap custom overwriting, see libs/styles/vendors/bootstrap

  padding: 9.5px v.$input-padding-x;
  font-size: 15px;
  font-weight: 300;
  text-align: left;

  &,
  &:focus {
    background-color: c.$white;
    color: c.$black;
  }

  @media (min-width: v.$screen-lg-min) {
    $padding-y: 8.5px;

    padding-top: $padding-y;
    padding-bottom: $padding-y;
    font-size: 14px;
  }
}

%skeleton-animated-background {
  animation: skeleton-shine 1.6s ease infinite;
  -webkit-backface-visibility: hidden;
  background: c.$color-skeleton-base;
  background: linear-gradient(
      90deg,
      c.$color-skeleton-base 25%,
      c.$color-skeleton-shine 37%,
      c.$color-skeleton-base 63%
  );
  background-size: 400% 100%;
  background-position: 100% 50%;
}

%sidesheet-container {
  position: fixed;
  background: c.$theme-color-card-background;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: v.$sidesheet-width;
  z-index: v.$z-index-side-sheet;

  // default state of slideRightAnimation to prevent flicks:
  transform: translateX(100%);
}

%form-field-active-state {
  outline: 0 none;
  box-shadow: v.$box-shadow-input-field;
  border-color: c.$border-color-input-field-active;
}

%status-indicator-container {
  height: 24px;
  display: flex;
  align-items: center;
  margin-left: 10px;

  .status-indicator {
    height: 6px;
    width: 6px;
    border-radius: 100%;
    background: c.$theme-color-secondary-text;
  }
}
