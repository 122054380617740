$immomio-icons-font: "immomio-icons";

@font-face {
    font-family: $immomio-icons-font;
    src: url("/fonts/immomio-icons.woff?97c852464174a2dcf7a98ee92630b2f6") format("woff"),
url("/fonts/immomio-icons.woff2?97c852464174a2dcf7a98ee92630b2f6") format("woff2"),
url("/fonts/immomio-icons.ttf?97c852464174a2dcf7a98ee92630b2f6") format("truetype");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: immomio-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$immomio-icons-map: (
    "air-condition": "\f101",
    "archive": "\f102",
    "area": "\f103",
    "armchair": "\f104",
    "arrow-down": "\f105",
    "arrow-left": "\f106",
    "arrow-right": "\f107",
    "arrow-up": "\f108",
    "attic": "\f109",
    "balcony": "\f10a",
    "barrier-free": "\f10b",
    "basement": "\f10c",
    "bathtub": "\f10d",
    "bed": "\f10e",
    "bell-filled": "\f10f",
    "bidet": "\f110",
    "bike": "\f111",
    "blinders": "\f112",
    "bling": "\f113",
    "board": "\f114",
    "bubbletub": "\f115",
    "burger-menu-expand": "\f116",
    "burger-menu-reduce": "\f117",
    "burger-menu": "\f118",
    "bus": "\f119",
    "calendar": "\f11a",
    "camera": "\f11b",
    "chart-bar": "\f11c",
    "chart-line": "\f11d",
    "chart-pie": "\f11e",
    "chat-template": "\f11f",
    "chat": "\f120",
    "check-outlined": "\f121",
    "check": "\f122",
    "chevron-down": "\f123",
    "chevron-left": "\f124",
    "chevron-right": "\f125",
    "chevron-up": "\f126",
    "clipboard": "\f127",
    "clock": "\f128",
    "close-outlined": "\f129",
    "close": "\f12a",
    "coin": "\f12b",
    "commercial": "\f12c",
    "compass": "\f12d",
    "computer": "\f12e",
    "contact-list": "\f12f",
    "context-menu-outlined": "\f130",
    "context-menu": "\f131",
    "contract": "\f132",
    "cookie": "\f133",
    "copy": "\f134",
    "crane": "\f135",
    "crosshair": "\f136",
    "dashboard": "\f137",
    "data": "\f138",
    "document": "\f139",
    "door": "\f13a",
    "download": "\f13b",
    "editor": "\f13c",
    "energy-certificate": "\f13d",
    "error": "\f13e",
    "expand": "\f13f",
    "export": "\f140",
    "extinguisher": "\f141",
    "eye-closed": "\f142",
    "eye-open": "\f143",
    "filter": "\f144",
    "fire": "\f145",
    "fireplace": "\f146",
    "flashes": "\f147",
    "floor": "\f148",
    "folder-outlined": "\f149",
    "folder": "\f14a",
    "food": "\f14b",
    "garage": "\f14c",
    "garden": "\f14d",
    "gas-container": "\f14e",
    "gear": "\f14f",
    "globe": "\f150",
    "half-rooms": "\f151",
    "hammer": "\f152",
    "handicapped": "\f153",
    "heating": "\f154",
    "highway": "\f155",
    "history": "\f156",
    "hospital": "\f157",
    "house-door": "\f158",
    "house": "\f159",
    "info": "\f15a",
    "intercom": "\f15b",
    "internet-cable": "\f15c",
    "key": "\f15d",
    "keyboard": "\f15e",
    "kitchen": "\f15f",
    "laundry-room": "\f160",
    "laundry": "\f161",
    "law": "\f162",
    "lift": "\f163",
    "light-bulb": "\f164",
    "lightning-outlined": "\f165",
    "lightning": "\f166",
    "link": "\f167",
    "linkedin": "\f168",
    "list": "\f169",
    "location-outlined": "\f16a",
    "location": "\f16b",
    "lock-closed": "\f16c",
    "lock-open": "\f16d",
    "loggia": "\f16e",
    "logout": "\f16f",
    "magnifier": "\f170",
    "mail": "\f171",
    "masonry": "\f172",
    "megaphone": "\f173",
    "memory": "\f174",
    "minus-outlined": "\f175",
    "minus": "\f176",
    "mobile": "\f177",
    "money-bag": "\f178",
    "money-pig": "\f179",
    "monument": "\f17a",
    "more-money": "\f17b",
    "moving-box": "\f17c",
    "news": "\f17d",
    "note": "\f17e",
    "office": "\f17f",
    "paint-brush": "\f180",
    "paint-roller": "\f181",
    "paper-clip": "\f182",
    "paper-plane": "\f183",
    "parking": "\f184",
    "party-cannon": "\f185",
    "payment-request": "\f186",
    "pdf": "\f187",
    "pen": "\f188",
    "pet": "\f189",
    "phone": "\f18a",
    "pipe": "\f18b",
    "plane": "\f18c",
    "plug": "\f18d",
    "plus-outlined": "\f18e",
    "plus": "\f18f",
    "pool": "\f190",
    "publish": "\f191",
    "question-outlined": "\f192",
    "redirect": "\f193",
    "refresh-1": "\f194",
    "refresh": "\f195",
    "reporting": "\f196",
    "school": "\f197",
    "search-profile": "\f198",
    "senior": "\f199",
    "share": "\f19a",
    "shower": "\f19b",
    "skyscraper": "\f19c",
    "sorting": "\f19d",
    "stairs": "\f19e",
    "stairwell": "\f19f",
    "store": "\f1a0",
    "sun": "\f1a1",
    "tag": "\f1a2",
    "terrace": "\f1a3",
    "thumbs-up": "\f1a4",
    "ticket": "\f1a5",
    "toilet": "\f1a6",
    "train": "\f1a7",
    "trash": "\f1a8",
    "tv": "\f1a9",
    "upload": "\f1aa",
    "urinal": "\f1ab",
    "user": "\f1ac",
    "utility-room": "\f1ad",
    "warning": "\f1ae",
    "water": "\f1af",
    "wbs": "\f1b0",
    "whole-rooms": "\f1b1",
    "window": "\f1b2",
    "xing": "\f1b3",
);

.icon-air-condition:before {
    content: map-get($immomio-icons-map, "air-condition");
}
.icon-archive:before {
    content: map-get($immomio-icons-map, "archive");
}
.icon-area:before {
    content: map-get($immomio-icons-map, "area");
}
.icon-armchair:before {
    content: map-get($immomio-icons-map, "armchair");
}
.icon-arrow-down:before {
    content: map-get($immomio-icons-map, "arrow-down");
}
.icon-arrow-left:before {
    content: map-get($immomio-icons-map, "arrow-left");
}
.icon-arrow-right:before {
    content: map-get($immomio-icons-map, "arrow-right");
}
.icon-arrow-up:before {
    content: map-get($immomio-icons-map, "arrow-up");
}
.icon-attic:before {
    content: map-get($immomio-icons-map, "attic");
}
.icon-balcony:before {
    content: map-get($immomio-icons-map, "balcony");
}
.icon-barrier-free:before {
    content: map-get($immomio-icons-map, "barrier-free");
}
.icon-basement:before {
    content: map-get($immomio-icons-map, "basement");
}
.icon-bathtub:before {
    content: map-get($immomio-icons-map, "bathtub");
}
.icon-bed:before {
    content: map-get($immomio-icons-map, "bed");
}
.icon-bell-filled:before {
    content: map-get($immomio-icons-map, "bell-filled");
}
.icon-bidet:before {
    content: map-get($immomio-icons-map, "bidet");
}
.icon-bike:before {
    content: map-get($immomio-icons-map, "bike");
}
.icon-blinders:before {
    content: map-get($immomio-icons-map, "blinders");
}
.icon-bling:before {
    content: map-get($immomio-icons-map, "bling");
}
.icon-board:before {
    content: map-get($immomio-icons-map, "board");
}
.icon-bubbletub:before {
    content: map-get($immomio-icons-map, "bubbletub");
}
.icon-burger-menu-expand:before {
    content: map-get($immomio-icons-map, "burger-menu-expand");
}
.icon-burger-menu-reduce:before {
    content: map-get($immomio-icons-map, "burger-menu-reduce");
}
.icon-burger-menu:before {
    content: map-get($immomio-icons-map, "burger-menu");
}
.icon-bus:before {
    content: map-get($immomio-icons-map, "bus");
}
.icon-calendar:before {
    content: map-get($immomio-icons-map, "calendar");
}
.icon-camera:before {
    content: map-get($immomio-icons-map, "camera");
}
.icon-chart-bar:before {
    content: map-get($immomio-icons-map, "chart-bar");
}
.icon-chart-line:before {
    content: map-get($immomio-icons-map, "chart-line");
}
.icon-chart-pie:before {
    content: map-get($immomio-icons-map, "chart-pie");
}
.icon-chat-template:before {
    content: map-get($immomio-icons-map, "chat-template");
}
.icon-chat:before {
    content: map-get($immomio-icons-map, "chat");
}
.icon-check-outlined:before {
    content: map-get($immomio-icons-map, "check-outlined");
}
.icon-check:before {
    content: map-get($immomio-icons-map, "check");
}
.icon-chevron-down:before {
    content: map-get($immomio-icons-map, "chevron-down");
}
.icon-chevron-left:before {
    content: map-get($immomio-icons-map, "chevron-left");
}
.icon-chevron-right:before {
    content: map-get($immomio-icons-map, "chevron-right");
}
.icon-chevron-up:before {
    content: map-get($immomio-icons-map, "chevron-up");
}
.icon-clipboard:before {
    content: map-get($immomio-icons-map, "clipboard");
}
.icon-clock:before {
    content: map-get($immomio-icons-map, "clock");
}
.icon-close-outlined:before {
    content: map-get($immomio-icons-map, "close-outlined");
}
.icon-close:before {
    content: map-get($immomio-icons-map, "close");
}
.icon-coin:before {
    content: map-get($immomio-icons-map, "coin");
}
.icon-commercial:before {
    content: map-get($immomio-icons-map, "commercial");
}
.icon-compass:before {
    content: map-get($immomio-icons-map, "compass");
}
.icon-computer:before {
    content: map-get($immomio-icons-map, "computer");
}
.icon-contact-list:before {
    content: map-get($immomio-icons-map, "contact-list");
}
.icon-context-menu-outlined:before {
    content: map-get($immomio-icons-map, "context-menu-outlined");
}
.icon-context-menu:before {
    content: map-get($immomio-icons-map, "context-menu");
}
.icon-contract:before {
    content: map-get($immomio-icons-map, "contract");
}
.icon-cookie:before {
    content: map-get($immomio-icons-map, "cookie");
}
.icon-copy:before {
    content: map-get($immomio-icons-map, "copy");
}
.icon-crane:before {
    content: map-get($immomio-icons-map, "crane");
}
.icon-crosshair:before {
    content: map-get($immomio-icons-map, "crosshair");
}
.icon-dashboard:before {
    content: map-get($immomio-icons-map, "dashboard");
}
.icon-data:before {
    content: map-get($immomio-icons-map, "data");
}
.icon-document:before {
    content: map-get($immomio-icons-map, "document");
}
.icon-door:before {
    content: map-get($immomio-icons-map, "door");
}
.icon-download:before {
    content: map-get($immomio-icons-map, "download");
}
.icon-editor:before {
    content: map-get($immomio-icons-map, "editor");
}
.icon-energy-certificate:before {
    content: map-get($immomio-icons-map, "energy-certificate");
}
.icon-error:before {
    content: map-get($immomio-icons-map, "error");
}
.icon-expand:before {
    content: map-get($immomio-icons-map, "expand");
}
.icon-export:before {
    content: map-get($immomio-icons-map, "export");
}
.icon-extinguisher:before {
    content: map-get($immomio-icons-map, "extinguisher");
}
.icon-eye-closed:before {
    content: map-get($immomio-icons-map, "eye-closed");
}
.icon-eye-open:before {
    content: map-get($immomio-icons-map, "eye-open");
}
.icon-filter:before {
    content: map-get($immomio-icons-map, "filter");
}
.icon-fire:before {
    content: map-get($immomio-icons-map, "fire");
}
.icon-fireplace:before {
    content: map-get($immomio-icons-map, "fireplace");
}
.icon-flashes:before {
    content: map-get($immomio-icons-map, "flashes");
}
.icon-floor:before {
    content: map-get($immomio-icons-map, "floor");
}
.icon-folder-outlined:before {
    content: map-get($immomio-icons-map, "folder-outlined");
}
.icon-folder:before {
    content: map-get($immomio-icons-map, "folder");
}
.icon-food:before {
    content: map-get($immomio-icons-map, "food");
}
.icon-garage:before {
    content: map-get($immomio-icons-map, "garage");
}
.icon-garden:before {
    content: map-get($immomio-icons-map, "garden");
}
.icon-gas-container:before {
    content: map-get($immomio-icons-map, "gas-container");
}
.icon-gear:before {
    content: map-get($immomio-icons-map, "gear");
}
.icon-globe:before {
    content: map-get($immomio-icons-map, "globe");
}
.icon-half-rooms:before {
    content: map-get($immomio-icons-map, "half-rooms");
}
.icon-hammer:before {
    content: map-get($immomio-icons-map, "hammer");
}
.icon-handicapped:before {
    content: map-get($immomio-icons-map, "handicapped");
}
.icon-heating:before {
    content: map-get($immomio-icons-map, "heating");
}
.icon-highway:before {
    content: map-get($immomio-icons-map, "highway");
}
.icon-history:before {
    content: map-get($immomio-icons-map, "history");
}
.icon-hospital:before {
    content: map-get($immomio-icons-map, "hospital");
}
.icon-house-door:before {
    content: map-get($immomio-icons-map, "house-door");
}
.icon-house:before {
    content: map-get($immomio-icons-map, "house");
}
.icon-info:before {
    content: map-get($immomio-icons-map, "info");
}
.icon-intercom:before {
    content: map-get($immomio-icons-map, "intercom");
}
.icon-internet-cable:before {
    content: map-get($immomio-icons-map, "internet-cable");
}
.icon-key:before {
    content: map-get($immomio-icons-map, "key");
}
.icon-keyboard:before {
    content: map-get($immomio-icons-map, "keyboard");
}
.icon-kitchen:before {
    content: map-get($immomio-icons-map, "kitchen");
}
.icon-laundry-room:before {
    content: map-get($immomio-icons-map, "laundry-room");
}
.icon-laundry:before {
    content: map-get($immomio-icons-map, "laundry");
}
.icon-law:before {
    content: map-get($immomio-icons-map, "law");
}
.icon-lift:before {
    content: map-get($immomio-icons-map, "lift");
}
.icon-light-bulb:before {
    content: map-get($immomio-icons-map, "light-bulb");
}
.icon-lightning-outlined:before {
    content: map-get($immomio-icons-map, "lightning-outlined");
}
.icon-lightning:before {
    content: map-get($immomio-icons-map, "lightning");
}
.icon-link:before {
    content: map-get($immomio-icons-map, "link");
}
.icon-linkedin:before {
    content: map-get($immomio-icons-map, "linkedin");
}
.icon-list:before {
    content: map-get($immomio-icons-map, "list");
}
.icon-location-outlined:before {
    content: map-get($immomio-icons-map, "location-outlined");
}
.icon-location:before {
    content: map-get($immomio-icons-map, "location");
}
.icon-lock-closed:before {
    content: map-get($immomio-icons-map, "lock-closed");
}
.icon-lock-open:before {
    content: map-get($immomio-icons-map, "lock-open");
}
.icon-loggia:before {
    content: map-get($immomio-icons-map, "loggia");
}
.icon-logout:before {
    content: map-get($immomio-icons-map, "logout");
}
.icon-magnifier:before {
    content: map-get($immomio-icons-map, "magnifier");
}
.icon-mail:before {
    content: map-get($immomio-icons-map, "mail");
}
.icon-masonry:before {
    content: map-get($immomio-icons-map, "masonry");
}
.icon-megaphone:before {
    content: map-get($immomio-icons-map, "megaphone");
}
.icon-memory:before {
    content: map-get($immomio-icons-map, "memory");
}
.icon-minus-outlined:before {
    content: map-get($immomio-icons-map, "minus-outlined");
}
.icon-minus:before {
    content: map-get($immomio-icons-map, "minus");
}
.icon-mobile:before {
    content: map-get($immomio-icons-map, "mobile");
}
.icon-money-bag:before {
    content: map-get($immomio-icons-map, "money-bag");
}
.icon-money-pig:before {
    content: map-get($immomio-icons-map, "money-pig");
}
.icon-monument:before {
    content: map-get($immomio-icons-map, "monument");
}
.icon-more-money:before {
    content: map-get($immomio-icons-map, "more-money");
}
.icon-moving-box:before {
    content: map-get($immomio-icons-map, "moving-box");
}
.icon-news:before {
    content: map-get($immomio-icons-map, "news");
}
.icon-note:before {
    content: map-get($immomio-icons-map, "note");
}
.icon-office:before {
    content: map-get($immomio-icons-map, "office");
}
.icon-paint-brush:before {
    content: map-get($immomio-icons-map, "paint-brush");
}
.icon-paint-roller:before {
    content: map-get($immomio-icons-map, "paint-roller");
}
.icon-paper-clip:before {
    content: map-get($immomio-icons-map, "paper-clip");
}
.icon-paper-plane:before {
    content: map-get($immomio-icons-map, "paper-plane");
}
.icon-parking:before {
    content: map-get($immomio-icons-map, "parking");
}
.icon-party-cannon:before {
    content: map-get($immomio-icons-map, "party-cannon");
}
.icon-payment-request:before {
    content: map-get($immomio-icons-map, "payment-request");
}
.icon-pdf:before {
    content: map-get($immomio-icons-map, "pdf");
}
.icon-pen:before {
    content: map-get($immomio-icons-map, "pen");
}
.icon-pet:before {
    content: map-get($immomio-icons-map, "pet");
}
.icon-phone:before {
    content: map-get($immomio-icons-map, "phone");
}
.icon-pipe:before {
    content: map-get($immomio-icons-map, "pipe");
}
.icon-plane:before {
    content: map-get($immomio-icons-map, "plane");
}
.icon-plug:before {
    content: map-get($immomio-icons-map, "plug");
}
.icon-plus-outlined:before {
    content: map-get($immomio-icons-map, "plus-outlined");
}
.icon-plus:before {
    content: map-get($immomio-icons-map, "plus");
}
.icon-pool:before {
    content: map-get($immomio-icons-map, "pool");
}
.icon-publish:before {
    content: map-get($immomio-icons-map, "publish");
}
.icon-question-outlined:before {
    content: map-get($immomio-icons-map, "question-outlined");
}
.icon-redirect:before {
    content: map-get($immomio-icons-map, "redirect");
}
.icon-refresh-1:before {
    content: map-get($immomio-icons-map, "refresh-1");
}
.icon-refresh:before {
    content: map-get($immomio-icons-map, "refresh");
}
.icon-reporting:before {
    content: map-get($immomio-icons-map, "reporting");
}
.icon-school:before {
    content: map-get($immomio-icons-map, "school");
}
.icon-search-profile:before {
    content: map-get($immomio-icons-map, "search-profile");
}
.icon-senior:before {
    content: map-get($immomio-icons-map, "senior");
}
.icon-share:before {
    content: map-get($immomio-icons-map, "share");
}
.icon-shower:before {
    content: map-get($immomio-icons-map, "shower");
}
.icon-skyscraper:before {
    content: map-get($immomio-icons-map, "skyscraper");
}
.icon-sorting:before {
    content: map-get($immomio-icons-map, "sorting");
}
.icon-stairs:before {
    content: map-get($immomio-icons-map, "stairs");
}
.icon-stairwell:before {
    content: map-get($immomio-icons-map, "stairwell");
}
.icon-store:before {
    content: map-get($immomio-icons-map, "store");
}
.icon-sun:before {
    content: map-get($immomio-icons-map, "sun");
}
.icon-tag:before {
    content: map-get($immomio-icons-map, "tag");
}
.icon-terrace:before {
    content: map-get($immomio-icons-map, "terrace");
}
.icon-thumbs-up:before {
    content: map-get($immomio-icons-map, "thumbs-up");
}
.icon-ticket:before {
    content: map-get($immomio-icons-map, "ticket");
}
.icon-toilet:before {
    content: map-get($immomio-icons-map, "toilet");
}
.icon-train:before {
    content: map-get($immomio-icons-map, "train");
}
.icon-trash:before {
    content: map-get($immomio-icons-map, "trash");
}
.icon-tv:before {
    content: map-get($immomio-icons-map, "tv");
}
.icon-upload:before {
    content: map-get($immomio-icons-map, "upload");
}
.icon-urinal:before {
    content: map-get($immomio-icons-map, "urinal");
}
.icon-user:before {
    content: map-get($immomio-icons-map, "user");
}
.icon-utility-room:before {
    content: map-get($immomio-icons-map, "utility-room");
}
.icon-warning:before {
    content: map-get($immomio-icons-map, "warning");
}
.icon-water:before {
    content: map-get($immomio-icons-map, "water");
}
.icon-wbs:before {
    content: map-get($immomio-icons-map, "wbs");
}
.icon-whole-rooms:before {
    content: map-get($immomio-icons-map, "whole-rooms");
}
.icon-window:before {
    content: map-get($immomio-icons-map, "window");
}
.icon-xing:before {
    content: map-get($immomio-icons-map, "xing");
}
