@use 'sass:color';
@import '../../shared/scss/colors';

// Override any imported variables here...

/* White-label colors */

$color-primary: var(--color-primary, #3486ef); // whitelabel
$color-primary-hover: var(
  --color-primary-hover,
  color.scale(#3486ef, $lightness: -20%)
); // inherit whitelabel $color-primary
$color-secondary: var(--color-secondary, #193f70); // whitelabel
$color-secondary-hover: var(
  --color-secondary-hover,
  color.scale(#193f70, $lightness: -20%)
); // inherit whitelabel $color-secondary

$color-surface: var(--color-surface, #ffffff); // whitelabel
$color-surface-hover: var(
  --color-surface-hover,
  color.scale(#ffffff, $lightness: -5%)
); // inherit whitelabel $color-surface
$color-surface-current: #e6e8ea;

$color-background-light: var(--color-background-light, #f5f8ff); // whitelabel
$color-background-medium: var(
  --color-background-medium,
  color.scale(#f5f8ff, $lightness: -3%, $saturation: 50%)
); // inherit whitelabel $color-background-light
$color-background-white: #ffffff;

$color-border: #ced2d5;
$color-border-hover: color.scale($color-border, $lightness: -20%);
$color-border-disabled: color.scale($color-border, $lightness: 50%);
$color-border-light: color.scale(
  $color-border,
  $lightness: 50%
); // same like $color-border-disabled

$color-text-primary: var(--color-text-primary, #050b13); // whitelabel
$color-text-secondary: var(--color-text-secondary, #7c8592); // whitelabel
$color-text-disabled: #ced2d5;
$color-text-white: #ffffff;

// addition button text colors to switch between white and black depending on the product colors
$color-text-button-primary: var(--color-text-button-primary, #ffffff); // depending on color primary
$color-text-button-secondary: var(
  --color-text-button-secondary,
  #ffffff
); // depending on color secondary

$theme-color-app-background: $color-background-white;
$theme-color-card-background: $color-surface;
$theme-color-menu-background: $color-text-primary;

$theme-color-primary-accent: $color-primary;
$theme-color-primary-accent-bw-contrast: #fff;
$theme-color-primary-accent-light: #85b6f5;
$theme-color-primary-accent-light-bw-contrast: #000;
$theme-color-primary-accent-dark: #1167d8;
$theme-color-primary-accent-dark-bw-contrast: #fff;

$theme-color-secondary-accent: $color-secondary;
$theme-color-secondary-accent-bw-contrast: #fff;
$theme-color-secondary-accent-light: #4886d6;
$theme-color-secondary-accent-light-bw-contrast: #fff;

$theme-color-secondary-accent-dark: #14325a;
$theme-color-secondary-accent-dark-bw-contrast: #fff;

$theme-color-primary-text: $color-text-primary;
$theme-color-secondary-text: $color-text-secondary;
$theme-color-button-text: $color-text-button-primary;

$theme-color-state-100-ultra-light: #eff9f3;
$theme-color-state-100-light: $color-success-light;
$theme-color-state-100: $color-success;
$theme-color-state-100-dark: $color-success-hover;

$theme-color-state-300: $color-good;
$theme-color-state-500: $color-moderate;
$theme-color-state-700: $color-low;

$theme-color-state-900-ultra-light: #fbf0f0;
$theme-color-state-900-light: #f4d2d1;
$theme-color-state-900: #dc6965;
$theme-color-state-900-dark: #d03631;

$theme-color-neutral: $color-text-disabled;
$theme-color-neutral-alpha-50: #ced2d57f;

$white: #ffffff;
$black: #000;
$yellow: $color-macadamia;
$grey: $theme-color-secondary-text;
$red: #dc6965;
$platinum: #ced2d5;
$blue: #1088d3;

$color-card-border: $theme-color-neutral;
$color-card-border-hover: $theme-color-secondary-text;

$color-card-border-active: $color-primary;

$color-card-border-state-100: $theme-color-state-100;
$color-card-border-alert: #d81b60;

$color-skeleton-base: $theme-color-neutral-alpha-50;
$color-skeleton-shine: $theme-color-app-background;

$border-color-input-field-active: $theme-color-secondary-text;

$background-light: #ffffff;
$theme-color-button-text: $color-text-button-primary;

$color-navigation-dark: #050b13;
